import React, { useState } from 'react';
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Slide, Toolbar, Typography, useScrollTrigger, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import logo from 'app/assets/logo.svg';
import Link from 'app/components/Link';
import HideOnScroll from 'app/components/HideOnScroll';

export default function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  const pages = [
    { isHashLink: true, to: '/#home', title: 'START' },
    { isHashLink: true, to: '/#nasze-uslugi', title: 'NASZE USŁUGI' },
    // { isHashLink: false, to: 'nasze-uslugi', title: 'DLACZEGO WARTO' },
    { isHashLink: true, to: '/#pytania-i-odpowiedzi', title: 'Q&A' },
    { isHashLink: true, to: '/#kontakt', title: 'KONTAKT' },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return <HideOnScroll>
    <AppBar position="fixed" elevation={0} sx={{
      height: '142px',
      pt: '72px',
      backgroundColor: theme.palette.common.biru10,
      boxShadow: '0px 3px 5px -5px rgb(76, 116, 172, 20%), 0px 5px 8px -8px rgb(76, 116, 172, 14%), 0px 1px 14px -14px rgb(76, 116, 172, 12%)',
    }} >
      <Container maxWidth={false} sx={{
        maxWidth: '1800px',
        [theme.breakpoints.up(1800)]: {
          left: 'calc(50% - 900px)',
        },
        mt: '-73px',
        [theme.breakpoints.down(500)]: {
          padding: 0,
        },
      }}>
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            [theme.breakpoints.down(500)]: {
              padding: 0,
            },
          }}
        >
          <Link hashLink to="/#home">
            <Box component="img" src={logo} alt='logo G-KLIM' sx={{
              height: 'auto',
              width: '200px',
              ml: '-16px',
            }} />
          </Link>
          <Box sx={{
            flexGrow: 1,
            mt: '-8px',
            justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' }
          }}>
            {pages.map(({ to, isHashLink, title }, index) => (
              <Link
                key={index}
                to={to}
                hashLink={isHashLink}
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ ml: '16px', color: theme.palette.common.hitam, fontWeight: 600, fontSize: '16px', display: 'block' }}
                >
                  {title}
                </Button>
              </Link>
            ))}
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mt: '-8px' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: theme.palette.common.hitam, transform: 'scale(1.3)' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(({ to, isHashLink, title }, index) => (
                <Link
                  key={index}
                  to={to}
                  hashLink={isHashLink}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">
                      {title}
                    </Typography>
                  </MenuItem>
                </Link>
              )
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  </HideOnScroll>
}
