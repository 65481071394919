import { Box, Button, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import airConditioner from 'app/assets/air-conditioner.svg';
import Label from "./Label";
import CallMeBackSection from "./CallMeBackSection";
import Footer from "./Footer";
import Link from "./Link";

const InstallationServiceSection = () => {
    const theme = useTheme();

    const StyledTitle = styled(Typography)({
        color: theme.palette.common.biru,
        fontWeight: 700,
        fontSize: "24px",
        marginBottom: "32px",
    });

    const StyledText = styled(Typography)({
        color: theme.palette.common.abu,
        fontWeight: 300,
        fontSize: "18px",
    });

    return (
        <>
            <Box sx={{
                height: 'auto',
                maxWidth: '100%',
            }}>
                <Box component='div' id='home' sx={{
                    pt: '200px',
                    mb: '64px',
                    height: '60%',
                    backgroundColor: theme.palette.common.biru10,
                    [theme.breakpoints.down(960)]: {
                        height: '65%',
                    },
                }}>
                    <Box sx={{
                        width: '75%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        ml: 'auto',
                        mr: 'auto',
                        [theme.breakpoints.down(1200)]: {
                            ml: '0px',
                        },
                        [theme.breakpoints.down(960)]: {
                            ml: 'auto',
                            flexDirection: 'column',

                        },
                    }}>
                        <Box sx={{
                            mt: 10,
                            height: '41vh',
                            maxWidth: '500px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            [theme.breakpoints.down(960)]: {
                                height: 'auto',
                                mt: '96px',
                            },
                            [theme.breakpoints.down(450)]: {
                            },
                        }}>
                            <Label textContent="NASZE USŁUGI" />
                            <Typography sx={{
                                fontSize: '48px',
                                fontWeight: 500,
                                color: theme.palette.common.hitam,
                                mt: '20px',
                                mb: '8px',
                                [theme.breakpoints.down(450)]: {
                                    fontSize: '32px',
                                },
                            }}>
                                MONTAŻ KLIMATYZACJI
                            </Typography>
                            <Typography sx={{
                                fontSize: '18px',
                                fontWeight: 300,
                                color: theme.palette.common.hitam,
                                [theme.breakpoints.down(450)]: {
                                    fontSize: '16px',
                                },
                            }}>
                                Oferujemy usługę montażu klimatyzacji. Nasz doświadczony zespół doradzi w sprawie umiejscowienia oraz doboru odpowiednich urządzeń. Usługę wykonamy starannie, dbając o Twój dobytek.
                            </Typography>

                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Box component="img" src={airConditioner} alt='klimatyzator powietrza' sx={{
                                mr: '-200px',
                                ml: '100px',
                                mt: '50px',
                                height: '65vh',
                                maxHeight: '600px',
                                [theme.breakpoints.down(1400)]: {
                                    ml: '50px',
                                    maxHeight: '500px',
                                },
                                [theme.breakpoints.down(1200)]: {
                                    mr: '-700px',
                                },
                                [theme.breakpoints.down(550)]: {
                                    ml: '0px',
                                },
                                [theme.breakpoints.down(450)]: {
                                    ml: '-30px',
                                },
                            }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                maxWidth: '100%',
                mt: '0px',
                p: '16px',
                pb: '96px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                [theme.breakpoints.down(960)]: {
                    mt: '36px',

                },

            }}>
                <Box sx={{
                    maxWidth: '350px',
                    minWidth: '250px',
                    p: '32px',
                    flex: 1,


                }}>
                    <StyledTitle>Darmowa wycena</StyledTitle>
                    <StyledText>
                        Podczas bezpłatnej wizyty omówimy zakres prac, uwzględniając Twoje potrzeby. Indywidualnie wycenimy montaż oraz doradzimy jakie urządzenia będą najlepiej spełniać Twoje oczekiwania. Po ustaleniu szczegółów, nie zaskoczą Cię żadne ukryte koszty.
                    </StyledText>
                </Box>
                <Box sx={{
                    maxWidth: '350px',
                    minWidth: '250px',
                    p: '32px',
                    flex: 1,

                }}>
                    <StyledTitle>Sprawna instalacja</StyledTitle>
                    <StyledText>
                        Posiadamy fachową wiedzę oraz doświadczenie. Jesteśmy na bieżąco z aktualnymi rozwiązaniami. Zakupione urządzenia możemy dostarczyć bezpośrednio na miejsce instalacji, a samą instalację przeprowadzimy w możliwie najmniej uciążliwy sposób.
                    </StyledText>
                </Box>
                <Box sx={{
                    maxWidth: '350px',
                    minWidth: '250px',
                    p: '32px',
                    flex: 1,
                }}>
                    <StyledTitle>Czysty montaż</StyledTitle>
                    <StyledText>
                        Dokładamy wszelkich starań, aby zadbać o czystość w miejscu instalacji. W razie potrzeby zabezpieczymy meble, sprzęty domowe i inne elementy wystroju. Najważniejsze jest dla nas Twoje zadowolenie.
                    </StyledText>
                </Box>
            </Box>
            <Box sx={{
                width: '50%',
                position: 'relative',
                left: '46%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'center',
                [theme.breakpoints.down(676)]: {
                    width: '90%',
                    left: '5%',
                    alignItems: 'center',
                }
            }}>
                <Box>
                    <Typography sx={{
                        fontWeight: 400,
                        fontSize: '18px',
                        color: theme.palette.common.hitam,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'right',
                        overflowWrap: 'break-word',
                        mr: '8px',
                        [theme.breakpoints.down(676)]: {
                            textAlign: 'center',
                        }
                    }}>
                        {`Sprawdź też naszą usługę serwisu\xa0klimatyzacji:`}
                    </Typography>
                </Box>
                <Link
                    to={'/serwis-klimatyzacji'}
                >
                    <Button
                        variant="contained"
                        sx={{
                            py: '16px',
                            width: '240px',
                            mr: '8px',
                            mt: '16px',
                            mb: '32px',
                            backgroundColor: theme.palette.common.biru,
                            fontSize: '18px',
                            fontWeight: 500,
                            borderRadius: '0px',
                            [theme.breakpoints.down(676)]: {
                                width: '75vw',
                            }
                        }}
                    >
                        Serwis klimatyzacji
                    </Button></Link>
            </Box>
            <CallMeBackSection />
            <Footer />
        </>
    );
};

export default InstallationServiceSection;
