
import React from 'react';
import { Box } from '@mui/material';

interface FooterColumnOwnProps {
  children: JSX.Element;
}
type FooterColumnProps = FooterColumnOwnProps;

export default function FooterColumn({ children }: FooterColumnProps) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '300px',
      mb: '32px',
    }}>
      {children}
    </Box>
  )
}
