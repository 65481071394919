import React, { useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useTheme } from "@mui/material";
import EmailService from "app/services/email.service";
import emailTemplate from "app/shared/emailTemplate";

interface CallMeBackDialogOwnProps {
  open: boolean;
  onClose: () => void;
}
type CallMeBackDialogProps = CallMeBackDialogOwnProps;

export default function CallMeBackDialog({ open, onClose }: CallMeBackDialogProps) {
  const theme = useTheme();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [isThankYouSectionVisible, setIsThankYouSectionVisible] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const [isProgressVisible, setIsProgressVisible] = useState<boolean>(false);

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numberRegex = /^\d+$/;

    if (!numberRegex.test(value) && value !== '') {
      return;
    }

    setPhoneNumberError(null);
    setPhoneNumber(value);
  }
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setName(value);
  }
  const isPhoneNumberValid = (phoneNumber: string) => {
    const phoneRegex = /^\d{9}$/;
    return phoneRegex.test(phoneNumber) || phoneNumber === '';
  }
  const handlePhoneNumberBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!isPhoneNumberValid(value)) {
      setPhoneNumberError('invalidPhoneNumber');
    }
  }
  const handleNameBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // const { value } = event.target;
  }

  const handleCallMeBackButtonClick = () => {
    setIsProgressVisible(true);
    EmailService.sendEmail('Ktoś właśnie poprosił o kontakt!', emailTemplate(phoneNumber, name)).then(() => {
      setIsProgressVisible(false);
      // console.log('Email sent successfully');
      setIsThankYouSectionVisible(true);
    }).catch((error) => {
      setIsProgressVisible(false);
      // console.error('Failed to send email:', error);
      setErrorOccured(true);
    });
  }

  return (
    <Dialog open={open} onClose={onClose}>
      {!isThankYouSectionVisible && !errorOccured && <Box sx={{
        p: '32px',
        width: '400px',
        maxWidth: 'calc(88% - 36px)',
        overflowX: 'visible',


      }}>
        {isProgressVisible && <CircularProgress sx={{
          position: 'absolute',
          left: 'calc(50% - 20px)',
          top: 'calc(50% - 20px)',
        }}/>}
        <DialogTitle sx={{
          p: 0,
        }}>
          <Typography sx={{
            fontSize: '24px',
            fontWeight: 500,
            color: theme.palette.common.hitam,
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
            mb: '16px',
            overflowWrap: 'break-word',
          }}>
            {'Zamów kontakt\n'}
          </Typography>
          <Typography sx={{
            fontWeight: 400,
            fontSize: '18px',
            color: theme.palette.common.hitam,
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
            mb: '32px',
            overflowWrap: 'break-word',
          }}>
            {'Oddzwonimy do Ciebie jak najszybciej.\n'}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{
          p: 0,
          '*': {
            borderRadius: 0,
          }
        }}>
          <Box
          >
            <TextField
              value={phoneNumber}
              error={phoneNumberError !== null}
              helperText={phoneNumberError !== null && "Wprowadź poprawny numer telefonu"}
              onChange={handlePhoneNumberChange}
              onBlur={handlePhoneNumberBlur}
              autoFocus
              margin="dense"
              id="phone"
              label="Numer telefonu"
              type="phone"
              fullWidth
              variant="outlined"
              required
            />
            <TextField
              value={name}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              margin="dense"
              id="name"
              label="Imię"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{
          padding: 0,
        }}>
          <Button onClick={handleCallMeBackButtonClick} variant="contained"
            disabled={phoneNumber === '' || phoneNumberError !== null || !isPhoneNumberValid(phoneNumber) || isProgressVisible}

            sx={{
              mt: '32px',
              width: '100%',
              py: '17px',
              backgroundColor: theme.palette.common.biru,
              borderRadius: 0,
              fontSize: '14px',
              fontWeight: 500,
            }}>Zamawiam kontakt</Button>
        </DialogActions>
        <Typography sx={{
          fontSize: '14px',
          fontWeight: 300,
          color: theme.palette.common.hitam,
          mt: '32px',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
        }}>
          {"Jeśli wolisz, możesz skontaktować się z nami \npod numerem "}
          <Box component={'span'} sx={{
            fontWeight: 600,
          }}>{`531\xa0531\xa0472`}</Box>
        </Typography>
      </Box>}

      {isThankYouSectionVisible &&
        <Box sx={{
          p: '32px',
          width: '400px',
          maxWidth: 'calc(88% - 36px)',
          overflowX: 'visible',

        }}>
          <DialogTitle sx={{
            p: 0,
          }}>
            <Typography sx={{
              fontSize: '24px',
              fontWeight: 500,
              color: theme.palette.common.hitam,
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              mb: '16px',
              overflowWrap: 'break-word',
            }}>
              {'Otrzymaliśmy Twoje zgłoszenie.\nWkrótce do Ciebie zadzwonimy.'}
            </Typography>
          </DialogTitle>
          <DialogActions sx={{
            padding: 0,
          }}>
            <Button onClick={() => { onClose() }} variant="contained"
              sx={{
                mt: '32px',
                width: '100%',
                py: '17px',
                backgroundColor: theme.palette.common.biru,
                borderRadius: 0,
                fontSize: '14px',
                fontWeight: 500,
              }}>Zamknij</Button>
          </DialogActions>
        </Box>
      }

      {errorOccured &&
        <Box sx={{
          p: '32px',
          width: '400px',
          maxWidth: 'calc(88% - 36px)',

          overflowX: 'visible',
        }}>
          <DialogTitle sx={{
            p: 0,
          }}>
            <Typography sx={{
              fontSize: '24px',
              fontWeight: 500,
              color: theme.palette.common.hitam,
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              mb: '16px',
              overflowWrap: 'break-word',
            }}>
              {'Niestety, nie udało się zapisać zgłoszenia.'}
            </Typography>

            <Typography sx={{
              fontSize: '16px',
              fontWeight: 400,
              color: theme.palette.common.hitam,
              mt: '32px',
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
            }}>
              {`Spróbuj ponownie później, lub skontaktuj się z\xa0nami pod\xa0numerem `}
              <Box component={'span'} sx={{
                fontWeight: 600,
              }}>{`531\xa0531\xa0472`}</Box>
            </Typography>
          </DialogTitle>
          <DialogActions sx={{
            padding: 0,
          }}>
            <Button onClick={() => { onClose() }} variant="contained"
              sx={{
                mt: '32px',
                width: '100%',
                py: '17px',
                backgroundColor: theme.palette.common.biru,
                borderRadius: 0,
                fontSize: '14px',
                fontWeight: 500,
              }}>Zamknij</Button>
          </DialogActions>
        </Box>
      }

    </Dialog>
  )
}
