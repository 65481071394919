const commonQuestions = [
    {
      question: 'Jak często wymagany jest serwis klimatyzacji?',
      answer: 'Dla użytku domowego, przy mało używanym układzie wymagany jest minimum jeden serwis w ciągu roku. Jeśli używamy klimatyzatora częściej, potrzebne będą dwa serwisy, lub nawet jeden na kwartał, jeśli używamy klimatyzatora całorocznie.',
    },
    {
      question: 'Jak długo posłuży mi nowy klimatyzator?',
      answer: `To zależy od kilku czynników: 
      > sposób instalacji systemu,
      > regularność serwisu,
      > umiejscowienie jednostek (szybciej zużywają się urządzenia narażone na ostre warunki klimatyczne, na przykład przyspieszona korozja na obszarach nadmorskich),
      > częstotliwość użytkowania jednostek. Odpowiednio zadbany klimatyzator może pracować około 10-15, a nawet 20 lat.`,
    },
    {
      question: 'Kiedy powinienem wymienić klimatyzator?',
      answer: `Istnieje kilka czynników, od których może zależeć konieczność wymiany klimatyzatora: 
      > Wiek urządzenia - przeciętne urządzenia klimatyzacyjne są w stanie pracowac 10 do nawet 20 lat. Jesli Twój klimatyzator ma już ponad 10 lat, to możę być czas na wymianę.
      > Rosnące rachunki za prąd - im klimatyzator starszy, tym mniejsza jego efektywność energetyczna. Oznacza to, że potrzebuje więcej energii, aby pracować na określonym poziomie obciążenia, niż kiedy był sprawny. 
      > Nieszczelny układ - jeśli w układzie wystąpił wyciek czynnika chłodzącego, mogło to spowodować uszkodzenie sprężarki. W takim wypadku konieczna będzie naprawa urządzenia, bądź jego całkowita wymiana. W przypadku starszych klimatyzatorów problemem może być również dostęp do dedykowanego czynnika.`,
    },
    {
      question: 'Czy klimatyzator można używać jedynie latem?',
      answer: 'Nie, klimatyzatory są urządzeniami uniwersalnymi i można je używać zarówno latem jak i zimą. W trybie chłodzenia pomagają utrzymać w pomieszczeniu optymalną temperaturę, a w trybie grzania pozwalają na ogrzewanie pomieszczeń.',
    },
    {
      question: 'Jak działa klimatyzator?',
      answer: 'Klimatyzator działa na zasadzie przenoszenia ciepła z jednego miejsca do drugiego. Wewnątrz urządzenia znajduje się czynnik chłodzący, który jest pompowany przez sprężarkę. Ciepłe powietrze jest pochłaniane przez urządzenie i przenoszone do jednostki zewnętrznej, gdzie następuje proces odparowania i schłodzenia czynnika chłodzącego. Schłodzone powietrze jest następnie przepompowywane do pomieszczenia, co pozwala na obniżenie temperatury.',
    },
    {
      question: 'Jak wybrać odpowiedni klimatyzator do mojego domu?',
      answer: `Przy wyborze klimatyzatora warto wziąć pod uwagę kilka ważnych czynników, takich jak: 
      > Rozmiar pomieszczenia, w którym będzie on zainstalowany, 
      > Ilość okien i drzwi w pomieszczeniu, 
      > Czy w pomieszczeniu jest instalacja grzewcza, 
      > Czy klimatyzator będzie używany całorocznie, 
      > Poziom hałasu, jaki jesteśmy w stanie zaakceptować,
      > Preferowana marka i budżet, 
      > Konsultacja z profesjonalistą może pomóc w dokonaniu właściwego wyboru dostosowanego do potrzeb i oczekiwań.`,
    },
    {
      question: 'Czy konieczne jest podłączenie klimatyzatora do sieci elektrycznej?',
      answer: 'Tak, klimatyzator jest urządzeniem elektrycznym i do jego prawidłowej pracy potrzebne jest zasilanie z sieci elektrycznej.',
    },
    {
      question: 'Jaką moc powinienem wybrać dla swojego domu/mieszkania?',
      answer: `Moc klimatyzatora powinna być dostosowana do powierzchni pomieszczenia, w którym będzie on używany. Przeciętnie, dla każdych 30 m² powierzchni pomieszczenia, potrzebna jest jednostka o mocy 2 kW. Warto jednak skonsultować się z specjalistą, który uwzględni indywidualne potrzeby i specyfikacje pomieszczenia.`,
    },
    {
      question: 'Jaka jest cena montażu klimatyzacji w domu?',
      answer: `Cena montażu klimatyzacji w domu może się różnić w zależności od kilku czynników, takich jak: 
      > Wielkość pomieszczeń, 
      > Typ wybranego urządzenia, 
      > Trudność montażu (np. konieczność przebudowy pomieszczenia),
      > Lokalizacja.
      
      Przeciętny koszt montażu klimatyzacji w domu to od 2000 do nawet 5000 złotych, w zależności od wybranego modelu i warunków instalacji.`,
    },
    {
      question: 'Czy potrzebne są dodatkowe elementy do klimatyzacji w domu?',
      answer: `W zależności od typu klimatyzatora i warunków instalacji, mogą być potrzebne dodatkowe elementy, takie jak:
      > Kanały wentylacyjne, 
      > Komponenty do instalacji centralnej, 
      > Filtry powietrza,
      > Dodatkowe jednostki zewnętrzne.
      
      Specjalista dokładnie określi, jakie elementy są potrzebne w Twoim przypadku, i jakie dodatkowe koszty wiążą się z ich z zakupem. `,
    },
    {
      question: 'Czy klimatyzacja może wpłynąć na jakość powietrza w pomieszczeniu?',
      answer: `Tak, klimatyzacja może wpłynąć na jakość powietrza w pomieszczeniu, zarówno polepszając je, jak i pogarszając jego jakość. Jeśli urządzenie nie będzie czyszczone wystarczająco często, mogą w nim namnażać się bakterie oraz rozwijać pleśń. Dlatego zaleca się regularne czyszczenie i serwisowanie jednostki. Warto również wybrać klimatyzator z filtrem HEPA, który pomaga w usuwaniu drobnoustrojów z powietrza.`,
    },
    {
      question: 'Czy klimatyzacja jest bezpieczna dla zdrowia?',
      answer: `Tak, klimatyzacja jest bezpieczna dla zdrowia, pod warunkiem, że jest odpowiednio zainstalowana i serwisowana. Regularny serwis pozwala na usunięcie kurzu i bakterii, które mogą się gromadzić w filtrach i rurach układu, co zwiększa ryzyko rozprzestrzeniania się chorób. Warto pamiętać, aby wybierać modele klimatyzatorów z wysokiej jakości filtrami powietrza, aby dodatkowo zwiększyć poziom ochrony zdrowia.`,
    },
    {
      question: 'Jakie są najczęstsze awarie klimatyzatorów?',
      answer: `Najczęstsze awarie klimatyzatorów to: 
      > Awarie elektryczne,
      > Uszkodzenie sprężarki,
      > Nieszczelny układ chłodniczy, 
      > Uszkodzenie wentylatora,
      > Awarie termostatu. 
      
      Regularne serwisowanie i konserwacja może pomóc w wykryciu i naprawie tych problemów, zanim staną się poważne i wymagające kosztownej naprawy.`,
    },
    {
      question: 'Jakie są rodzaje klimatyzatorów dostępne na rynku?',
      answer: 'Można wyróżnić dwa główne rodzaje klimatyzatorów: jednostka wewnętrzna i jednostka zewnętrzna. Jednostka wewnętrzna jest montowana wewnątrz pomieszczenia, natomiast jednostka zewnętrzna jest montowana na zewnątrz budynku. Istnieją również klimatyzatory przenośne, w których jednostka wewnętrzna i zewnętrzna są zintegrowane w jednym urządzeniu. ',
    },
    {
      question: 'Czy potrzebuję specjalistycznego sprzętu do montażu klimatyzacji?',
      answer: `Tak, montaż klimatyzacji wymaga specjalistycznego sprzętu i wiedzy, ponieważ wiele czynności wymaga odpowiedniej techniki i doświadczenia. Zaleca się, aby skorzystać z usług profesjonalnego serwisu klimatyzacji, który posiada odpowiednie doświadczenie i wiedzę, aby wykonać instalację w sposób bezpieczny i prawidłowy. `,
    },
    {
      question: 'Czy istnieją specjalne wymagania dotyczące instalacji klimatyzatora?',
      answer: `Tak, istnieją pewne wymagania dotyczące instalacji klimatyzatora, które należy spełnić, aby zagwarantować prawidłowe i bezpieczne działanie urządzenia: 
      > Instalacja powinna być wykonana przez wykwalifikowanego i certyfikowanego technika. 
      > Potrzebne jest zachowanie odpowiedniego dystansu od źródeł ciepła, takich jak kuchenki czy grzejniki. 
      > Klimatyzator powinien być połączony z właściwymi przewodami, takimi jak przewody odprowadzania skroplin czy przewody powietrza. 
      > Wymagana jest prawidłowa wentylacja.`,
    },
    {
      question: 'Czy klimatyzatory mają filtry powietrza?',
      answer: `Tak, większość klimatyzatorów jest wyposażona w filtry powietrza, których zadaniem jest jego oczyszczanie. Filtry te mogą być wykonane z różnych materiałów, takich jak włókno szklane, filtry HEPA lub filtry antyalergiczne. Regularne wymiany filtrów jest kluczowe dla utrzymania prawidłowego funkcjonowania urządzenia oraz jakości powietrza w pomieszczeniu.`,
    },
  ]

  export default commonQuestions;
  