const emailTemplate = (phoneNumber: string, name: string) => {
    const now = new Date();
    let month = (now.getMonth()+1).toString().length === 1? '0'+(now.getMonth()+1) : (now.getMonth()+1);
    const regDate = `${now.getDate()}.${month}.${now.getFullYear()}, ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`

    return `<!DOCTYPE html>
    <html lang="pl">
      <head>
        <title>G-KLIM - powiadomienie o nowej prośbie o kontakt</title>
        <link rel="stylesheet" href="path/to/font-awesome/css/font-awesome.min.css">
        <style type="text/css">
          .body {
            margin-left: auto;
            margin-right: auto;
            color: black;
            width: 100%;
            max-width: 800px;
            font-family: "Roboto", sans-serif;
          }
          .content {
            width: 100%;
            color: black !important;
            padding: 1rem;
            padding-top: 0rem;
          }
          .bottom {
            width: 100%;
            height: 8px;
            background-color: #273C58;
          }
          hr {
            border: solid 1px transparent;
          }
          .hint {
            display: flex;
            flex-direction: column;
          }
          .hint a {
            transition-duration: 0.3s;
            text-decoration: none;
            color: rgba(51, 51, 51, 0.5);
            padding: 0.25rem;
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
          }
          .hint a:hover {
            color: rgba(51, 51, 51, 0.9);
            padding-left: .5em;
          }
          
          .social-icons{
            margin-left: .5rem;
            margin-bottom: .8rem;
          }
          .social-icon{
            padding: .5rem;
            width: 40px;
            height: 40px;
            cursor: pointer;
            text-decoration: none;                   
          }
          .social-icon img{
            filter: grayscale(100%);
            
          }      
          .social-icon img:hover  {
            transition-duration: 0.3s;
            filter: grayscale(10%);      
            transform: scale(1.1);
          }
          table{
            padding: 1rem;
          }
          th{
            text-align: left;
          }
          td{
            text-align: left;
            padding-left: 1rem;
          }
          .hidden{
            color: transparent;
          }
        </style>
      </head>
      <body>
        <div class="body">
          <div class="hidden">
            ${now}
          </div>
          <img src="https://cdn.glitch.global/54761133-8c64-4305-b54e-e03bcadc53d9/Frame%2011.png?v=1676928574133" alt="G-KLIM logo" style="width: 150px;" />
          <div class="bottom"></div>
          <div class="content">
            <hr />
            <h4>
              Ktoś właśnie przesłał prośbę o kontakt. 
              <table>
                <tr>
                  <th>Imię: </th>
                  <td>${name}</td>
                </tr>
                <tr>
                  <th>Numer telefonu: </th>
                  <td>${phoneNumber}</td>
                </tr>
                
                <tr>
                  <th>Data: </th>
                  <td>${regDate}</td>
                </tr>          
              </table>
              
            </h4>
          </div>
    
          <div class="bottom"></div>
          <div class="hidden">
            ${now}
          </div>
        </div>
      </body>
    </html>`;
}

export default emailTemplate;
