import React, { useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import CallMeBackDialog from "./CallMeBackDialog";

export default function CallMeBackSection() {
  const theme = useTheme();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  return (
    <Box id={'kontakt'} sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.common.hitam,
      py: '56px',
      px: '16px',
    }}>
      <Typography sx={{
        fontSize: '48px',
        fontWeight: 600,
        color: theme.palette.common.putih,
        mb: '32px',
        textAlign: 'center',
      }}>
        ZAMÓW KONTAKT
      </Typography>
      <Typography sx={{
        fontSize: '24px',
        fontWeight: 500,
        color: theme.palette.common.putih,
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
        mb: '32px',
        overflowWrap: 'break-word',
      }}>
        {'Wprowadź swój numer. \nOddzwonimy do Ciebie w ciągu 24h.\n'}
        <Box component={'span'} sx={{
          fontWeight: 400,
          fontSize: '18px',
        }}>(W dni robocze, od 8 do 17)</Box>
      </Typography>
      <Button
        onClick={() => setIsDialogOpen(true)}
        variant="contained"
        sx={{
          py: '16px',
          width: '240px',
          mr: '8px',
          mb: '8px',
          backgroundColor: theme.palette.common.biru,
          fontSize: '18px',
          fontWeight: 500,
          borderRadius: '0px',
        }}
      >
        Zamów kontakt
      </Button>
      <Box>
        <Typography sx={{
          fontSize: '14px',
          fontWeight: 300,
          color: theme.palette.common.putih,
          mt: '64px',
          mb: '-32px',
          whiteSpace: 'pre',
          textAlign: 'center',
        }}>
          {"Jeśli wolisz, możesz skontaktować się z nami \npod numerem "}
          <Box component={'span'} sx={{
            fontWeight: 600,
          }}>{`531\xa0531\xa0472`}</Box>
        </Typography>
      </Box>
      { isDialogOpen ? <CallMeBackDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}/> : null}
    </Box>
  )
}
