
import React from 'react';
import { Typography, useTheme, Box } from '@mui/material';
import FooterColumn from 'app/components/FooterColumn';
import logo from 'app/assets/g-klim.svg';

export default function DetailsFooterColumn() {
  const theme = useTheme();

  return (
    <FooterColumn>
      <>
        <Box component="img" src={logo} alt='logo G-KLIM' sx={{
          height: '48px',
          width: '194px',
          mb: '32px',
        }} />
        <Typography sx={{
          pt: '1px',
          fontSize: '18px',
          fontWeight: 300,
          lineHeight: 1.9,
          whiteSpace: 'pre-wrap',
          color: theme.palette.common.abu,
        }} >
          {'Grzegorz Urbanik\nul. Leśna 2\n55-330 Wilkszyn\n'}
          <Box component={'span'} sx={{
            color: theme.palette.common.biru,
            fontWeight: '500',
            '& a': {
              textDecoration: 'none',
              color: theme.palette.common.biru,
            }
          }}>
            <a href="mailto:urbanik@onet.pl">urbanik@onet.pl</a>
          </Box>
          {'\n\nZadzwoń: \n'}
          <Box component={'span'} sx={{
            color: theme.palette.common.biru,
            fontWeight: '500',
            '& a': {
              textDecoration: 'none',
              color: theme.palette.common.biru,
            }
          }}>
            <a href="tel:531531472">{`531\xa0531\xa0472`}</a>
          </Box>
        </Typography>
      </>
    </FooterColumn>
  )
}
