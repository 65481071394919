import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "app/views/Home";
import Navbar from 'app/components/Navbar';
import { Box } from '@mui/material';
import InstallationServiceSection from 'app/components/InstallationServiceSection';
import ScrollToTop from 'app/wrapperComponents/ScrollToTop';
import MaintenanceServiceSection from 'app/components/MaintenanceServiceSection';

function App() {
  useEffect(() => {
    if (window.location.protocol == 'http:') {
      window.location.href =
        window.location.href.replace(
          'http:', 'https:');
    }
  }, []);

  return (
    <Box sx={{
      overflow: 'hidden',
    }}>
      <Navbar />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="montaz-klimatyzacji" element={<InstallationServiceSection />} />
          <Route path="serwis-klimatyzacji" element={<MaintenanceServiceSection />} />
        </Routes>
      </ScrollToTop>
    </Box>
  );
}

export default App;
