import React from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import workingMan from 'app/assets/working-man.png';
import varticalLine from 'app/assets/vertical-line.svg';
import Label from "./Label";
import Link from "./Link";

export default function AboutUsSection() {
  const theme = useTheme();

  return (
    <Box component='div' sx={{
    }}>
      <Box id='nasze-uslugi' sx={{
        width: '75%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        pb: '64px',
        maxWidth: '1800px',
        [theme.breakpoints.up(1800)]: {
          left: 'calc(50% - 900px)',
        },
        [theme.breakpoints.down(1330)]: {
          pl: '32px',
          width: '90%',
        },
        [theme.breakpoints.down(960)]: {
          pl: 0,
          flexDirection: 'column',
        },
      }}>
        <Box sx={{
          [theme.breakpoints.down(960)]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: '64px',
          },
        }}>
          <Box component="img" src={workingMan} alt='serwisowanie klimatyzacji' sx={{
            maxWidth: '40vw',
            maxHeight: '65vh',
            [theme.breakpoints.down(960)]: {
              maxWidth: 'none',
              maxHeight: 'none',
              width: '65%',
            },
            [theme.breakpoints.down(700)]: {
              width: '85vw',
            },
          }} />
        </Box>
        <Box sx={{
          mt: 10,
          pl: '64px',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          [theme.breakpoints.down(1330)]: {
            pl: '32px',
          },
          [theme.breakpoints.down(960)]: {
            pl: '0px',
          },
        }}>
          <Label textContent="NASZE USŁUGI" />
          <Typography sx={{
            fontSize: '40px',
            fontWeight: 600,
            color: theme.palette.common.hitam,
            mt: '24px',
            mb: '8px',
            whiteSpace: 'pre-wrap',
          }}>
            {"OBSŁUGA KLIMATYZACJI \nWROCŁAW I OKOLICE"}
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mr: '32px',
            }}>
              <Box component="img" src={varticalLine} alt='element listy' sx={{
                px: '8px',
                py: '8px',
              }} />
              <Typography sx={{
                pt: '1px',
                fontSize: '18px',
                fontStyle: 'italic',
                fontWeight: 300,
                color: theme.palette.common.abu,
              }}>
                Montaż klimatyzacji
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mr: '32px',
            }}>
              <Box component="img" src={varticalLine} alt='element listy' sx={{
                px: '8px',
                py: '8px',
              }} />
              <Typography sx={{
                pt: '1px',
                fontSize: '18px',
                fontStyle: 'italic',
                fontWeight: 300,
                color: theme.palette.common.abu,
              }}>
                Serwis klimatyzacji
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mr: '32px',
            }}>
              <Box component="img" src={varticalLine} alt='element listy' sx={{
                px: '8px',
                py: '8px',
              }} />
              <Typography sx={{
                pt: '1px',
                fontSize: '18px',
                fontStyle: 'italic',
                fontWeight: 300,
                color: theme.palette.common.abu,
              }}>
                Sprzedaż klimatyzatorów
              </Typography>
            </Box>
          </Box>
          <Typography sx={{
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: 1.9,
            color: theme.palette.common.abu,
            my: '48px',
          }}>
            Zajmujemy się montażem oraz wymianą systemów klimatyzacji na terenie Wrocławia i okolic. Przeprowadzamy profesjonalny serwis. Posiadamy potrzebne uprawnienia. Doradzamy w zakresie doboru odpowiednich urządzeń i rozwiązań.
          </Typography>
          <Box sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'nowrap',
            [theme.breakpoints.down(600)]: {
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
          }
          }}>
            <Link
              to={'montaz-klimatyzacji'}
            >
              <Button
                variant="contained"
                sx={{
                  py: '16px',
                  width: '240px',
                  mr: '8px',
                  mb: '8px',
                  backgroundColor: theme.palette.common.biru,
                  fontSize: '18px',
                  fontWeight: 500,
                  borderRadius: '0px',
                  [theme.breakpoints.down(600)]: {
                    width: '85vw',
                }
                }}
              >
                Montaż klimatyzacji
              </Button></Link>
            <Link
              to={'serwis-klimatyzacji'}
            >
              <Button
                variant="contained"
                sx={{
                  py: '16px',
                  width: '240px',
                  mr: '8px',
                  mb: '8px',
                  backgroundColor: theme.palette.common.biru,
                  fontSize: '18px',
                  fontWeight: 500,
                  borderRadius: '0px',
                  [theme.breakpoints.down(600)]: {
                    width: '85vw',
                }
                }}
              >
                Serwis klimatyzacji
              </Button></Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
