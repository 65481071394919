import React, { useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
    children: ReactNode;
}

export default function ScrollToTop({ children }: Props) {
    const location = useLocation();

    // useEffect(() => {
    //     // const unlisten = history.listen(() => {
    //         window.scrollTo(0, 0);
    //     // });
    //     // return () => {
    //     //     unlisten();
    //     // }
    // }, [history]);

    useEffect(() => {
        // const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        // });
        // return () => {
        //     unlisten();
        // }
    }, [location]);

    return (<>{children}</>)


}
