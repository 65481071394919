import React from "react";
import TopSection from "app/components/TopSection";
import AboutUsSection from "app/components/AboutUsSection";
import CommonQuestionsSection from "app/components/CommonQuestionsSection";
import CallMeBackSection from "app/components/CallMeBackSection";
import Footer from "app/components/Footer";
import { Box } from "@mui/material";

export default function Home() {
  return (
    <Box>
      <TopSection />
      <AboutUsSection />
      <CommonQuestionsSection />
      <CallMeBackSection />
      <Footer />
    </Box>
  );
}
