import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Link from "app/components/Link";
import womanWithRemote from 'app/assets/woman-with-remote.png';

export default function TopSection() {
  const theme = useTheme();

  return (
    <Box sx={{
      height: '100vh',
      maxHeight: '1100px',
      [theme.breakpoints.down(960)]: {
        maxHeight: '800px',
      },
    }}>
      <Box component='div' id='home' sx={{
        pt: '200px',
        mb: '200px',
        height: '60%',
        backgroundColor: theme.palette.common.biru10,
        [theme.breakpoints.down(960)]: {
          height: '65%',
        },
      }}>
        <Box sx={{
          width: '75%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          ml: 'auto',
          mr: 'auto',
          [theme.breakpoints.down(1200)]: {
            ml: '-50px',
          },
          [theme.breakpoints.down(960)]: {
            ml: 'auto',
          },
        }}>
          <Box sx={{
            mt: 10,
            height: '41vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            [theme.breakpoints.down(960)]: {
              height: 'auto',
              mt: '96px',
            },
            [theme.breakpoints.down(450)]: {
            },
          }}>
            <Typography sx={{
              fontSize: '52px',
              fontWeight: 900,
              color: theme.palette.common.hitam,
              mb: '8px',
              pr: '32px',
              [theme.breakpoints.down(450)]: {
                fontSize: '36px',
              },
            }}>
              KLIMATYZACJE
            </Typography>
            <Typography sx={{
              fontSize: '32px',
              fontWeight: 500,
              color: theme.palette.common.hitam,
              [theme.breakpoints.down(450)]: {
                fontSize: '24px',
              },
            }}>
              Zadbaj o swój <Box component='span' sx={{ color: theme.palette.common.biru, fontWeight: 800 }}>klimat<br /></Box> niezależnie od pogody
            </Typography>
            <Link hashLink to="/#nasze-uslugi">
              <Button
                variant="contained"
                sx={{
                  px: 6,
                  py: 1,
                  mt: '64px',
                  backgroundColor: theme.palette.common.biru,
                  borderRadius: '32px',
                  fontSize: '24px',
                  [theme.breakpoints.down(450)]: {
                    width: '90vw',
                  },
                }}
              >
                Poznaj nas
              </Button>
            </Link>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <Box component="img" src={womanWithRemote} alt='używanie klimatyzatora powietrza' sx={{
              mr: '-200px',
              ml: '100px',
              mt: '50px',
              height: '65vh',
              maxHeight: '600px',
              [theme.breakpoints.down(1400)]: {
                ml: '50px',
                maxHeight: '500px',
              },
              [theme.breakpoints.down(1200)]: {
                mr: '-700px',
              },
              [theme.breakpoints.down(960)]: {
                display: 'none',
                position: 'absolute',
              },
            }} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
