import React from "react";
import { Box, Typography, ImageList, ImageListItem, useMediaQuery, useTheme } from "@mui/material";
import commonQuestions from "app/shared/commonQuestions";

export default function CommonQuestionsSection() {
  const theme = useTheme();
  const largeScreen = useMediaQuery('(min-width:1300px)');
  const mediumScreen = useMediaQuery('(min-width:800px)') && !largeScreen;
  const getCols = () => {
    return largeScreen ? 3 : mediumScreen ? 2 : 1;
  }

  return (
    <Box id={'pytania-i-odpowiedzi'} sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: theme.palette.common.biru10,
      pb: '128px',
    }}>
      <Box sx={{
        maxWidth: '1800px',
        [theme.breakpoints.up(1800)]: {
          left: 'calc(50% - 900px)',
        },
      }}>
      <Typography sx={{
        fontSize: '40px',
        fontWeight: 600,
        pt: '64px',
        pb: '32px',
        color: theme.palette.common.hitam,
        maxWidth: '90%',
        ml: 'auto',
        mr: 'auto',
        textAlign: 'center',
      }}>Najczęściej zadawane pytania klientów <span style={{ whiteSpace: 'nowrap' }}>G-KLIM</span></Typography>
      <ImageList
        variant="masonry"
        cols={getCols()}
        gap={4}
        sx={{
          width: '90%',
          ml: 'auto',
          mr: 'auto',
          height: 'auto',
        }}>
        {commonQuestions.map((el) => {
          return (
            <ImageListItem
              key={el.question}
              sx={{
                p: '32px',
              }}>
              <Typography sx={{
                fontSize: '20px',
                fontWeight: 700,
                color: theme.palette.common.hitam,
              }}>
                {el.question}
              </Typography>
              <br />
              <Typography sx={{ whiteSpace: 'pre-line', fontSize: '16px', fontWeight: 400, color: theme.palette.common.hitam }}>
                {Array.from(el.answer).map((letter) => {
                  if (letter === '>') {
                    return <Box component={'span'} sx={{
                      color: theme.palette.common.biru,
                      fontWeight: 700,
                    }}>{'\xa0\xa0\xa0\xa0' + letter}</Box>
                  } else {
                    return letter;
                  }
                })}
              </Typography>
            </ImageListItem >
          )
        })}
      </ImageList>
      </Box>
    </Box>
  )
}
