import axios from 'axios';

interface EmailBody {
  subject: string;
  html: string;
}

const sendEmail = async (subject: string, html: string): Promise<void> => {
  const url = 'https://g-klim.site:4001/send';
  const emailBody: EmailBody = { subject, html };

  return axios.post(url, emailBody);
};

const EmailService = {
    sendEmail
}

export default EmailService;
