import { Box, Button, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import airConditioner from 'app/assets/air-conditioner.svg';
import Label from "./Label";
import CallMeBackSection from "./CallMeBackSection";
import Footer from "./Footer";
import Link from "./Link";

const MaintenanceServiceSection = () => {
    const theme = useTheme();

    const StyledTitle = styled(Typography)({
        color: theme.palette.common.biru,
        fontWeight: 700,
        fontSize: "24px",
        marginBottom: "32px",

    });

    const StyledText = styled(Typography)({
        color: theme.palette.common.abu,
        fontWeight: 300,
        fontSize: "18px",

    });

    return (
        <>
            <Box sx={{
                height: 'auto',
                maxWidth: '100%',
                [theme.breakpoints.down(960)]: {
                },
            }}>
                <Box component='div' id='home' sx={{
                    pt: '200px',
                    mb: '64px',
                    height: '60%',
                    backgroundColor: theme.palette.common.biru10,
                    [theme.breakpoints.down(960)]: {
                        height: '65%',
                    },
                }}>
                    <Box sx={{
                        width: '75%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        ml: 'auto',
                        mr: 'auto',
                        [theme.breakpoints.down(1200)]: {
                            ml: '0px',
                        },
                        [theme.breakpoints.down(960)]: {
                            ml: 'auto',
                            flexDirection: 'column',

                        },
                    }}>
                        <Box sx={{
                            mt: 10,
                            height: '41vh',
                            maxWidth: '500px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            [theme.breakpoints.down(960)]: {
                                height: 'auto',
                                mt: '96px',
                            },
                            [theme.breakpoints.down(450)]: {
                            },
                        }}>
                            <Label textContent="NASZE USŁUGI" />
                            <Typography sx={{
                                fontSize: '48px',
                                fontWeight: 500,
                                color: theme.palette.common.hitam,
                                mt: '20px',
                                mb: '8px',
                                [theme.breakpoints.down(450)]: {
                                    fontSize: '32px',
                                },
                            }}>
                                SERWIS KLIMATYZACJI
                            </Typography>
                            <Typography sx={{
                                fontSize: '18px',
                                fontWeight: 300,
                                color: theme.palette.common.hitam,
                                [theme.breakpoints.down(450)]: {
                                    fontSize: '16px',
                                },
                            }}>
                                Oferujemy usługę serwisu klimatyzacji, dzięki której Twoje urządzenie będzie działało sprawnie i bezawaryjnie przez długi czas. Nasi doświadczeni technicy przeprowadzą kompleksowy przegląd oraz konserwację Twojej klimatyzacji, w razie potrzeby uzupełnią czynnik chłodzący oraz przeprowadzą niezbędne naprawy.
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Box component="img" src={airConditioner} alt='klimatyzator powietrza' sx={{
                                mr: '-200px',
                                ml: '100px',
                                mt: '50px',
                                height: '65vh',
                                maxHeight: '600px',
                                [theme.breakpoints.down(1400)]: {
                                    ml: '50px',
                                    maxHeight: '500px',
                                },
                                [theme.breakpoints.down(1200)]: {
                                    mr: '-700px',
                                },
                            }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                maxWidth: '100%',
                mt: '0px',
                p: '16px',
                pb: '96px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                [theme.breakpoints.down(960)]: {
                    mt: '36px',
                },
            }}>
                <Box sx={{
                    maxWidth: '350px',
                    minWidth: '250px',
                    p: '32px',
                    flex: 1,


                }}>
                    <StyledTitle>Profesjonalne przeglądy</StyledTitle>
                    <StyledText>
                    W ramach naszej usługi serwisowej przeprowadzimy dokładny przegląd Twojej klimatyzacji, sprawdzając stan techniczny poszczególnych elementów oraz wskazując na ewentualne usterki. Dzięki ich wczesnemu wykryciu unikniesz kosztownych napraw oraz przedłużysz żywotność swojego urządzenia.
                    </StyledText>
                </Box>
                <Box sx={{
                    maxWidth: '350px',
                    minWidth: '250px',
                    p: '32px',
                    flex: 1,

                }}>
                    <StyledTitle>Czyszczenie i konserwacja</StyledTitle>
                    <StyledText>
                    Ważnym elementem serwisu klimatyzacji jest czyszczenie i konserwacja poszczególnych elementów, takich jak filtry czy skraplacze. Wszystko po to, aby Twój sprzęt działał efektywnie i zapewniał zdrowe powietrze w Twoim pomieszczeniu.
                    </StyledText>
                </Box>
                <Box sx={{
                    maxWidth: '350px',
                    minWidth: '250px',
                    p: '32px',
                    flex: 1,

                }}>
                    <StyledTitle>Naprawy i wymiana części</StyledTitle>
                    <StyledText>
                    W przypadku stwierdzenia awarii lub konieczności wymiany części, nasi technicy fachowo i profesjonalnie wykonają niezbędne naprawy lub wymienią poszczególne elementy. Możesz mieć pewność, że Twoja klimatyzacja zostanie naprawiona w krótkim czasie i przy minimalnych kosztach.
                    </StyledText>
                </Box>

            </Box>
            <Box sx={{
                width: '50%',
                position: 'relative',
                left: '46%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'center',

                [theme.breakpoints.down(676)]: {
                    width: '90%',
                    left: '5%',
                    alignItems: 'center',

                }
            }}>
                <Box>
                    <Typography sx={{
                        fontWeight: 400,
                        fontSize: '18px',
                        color: theme.palette.common.hitam,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'right',
                        overflowWrap: 'break-word',
                        mr: '8px',
                        [theme.breakpoints.down(676)]: {
                            textAlign: 'center',
                        }
                    }}>
                        {`Sprawdź też naszą usługę montażu\xa0klimatyzacji:`}
                    </Typography>
                </Box>
                <Link
                    to={'/montaz-klimatyzacji'}
                >
                    <Button
                        variant="contained"
                        sx={{
                            py: '16px',
                            width: '240px',
                            mr: '8px',
                            mt: '16px',
                            mb: '32px',
                            backgroundColor: theme.palette.common.biru,
                            fontSize: '18px',
                            fontWeight: 500,
                            borderRadius: '0px',
                            [theme.breakpoints.down(676)]: {
                                width: '75vw',
                            }
                        }}
                    >
                        Montaż klimatyzacji
                    </Button></Link>
            </Box>
            <CallMeBackSection />
            <Footer />
        </>
    );
};

export default MaintenanceServiceSection;
