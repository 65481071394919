import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import DetailsFooterColumn from "app/components/DetailsFooterColumn";
import NavigationFooterColumn from "app/components/NavigationFooterColumn";

export default function Footer() {
  const theme = useTheme();

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.common.biru10,
        px: '64px',
        py: '64px',
      }}>
        <DetailsFooterColumn />
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          [theme.breakpoints.down(1100)]: {
          flexDirection: 'column',

          }
        }}>
          <NavigationFooterColumn title='Usługi' navItems={[
            { hashLink: false, to: '/montaz-klimatyzacji', children: 'Montaż klimatyzacji' },
            { hashLink: false, to: '/serwis-klimatyzacji', children: 'Serwis klimatyzacji' },
          ]} />
          <NavigationFooterColumn title='Na skróty' navItems={[
            { hashLink: true, to: '/#home', children: 'Start' },
            { hashLink: true, to: '/#nasze-uslugi', children: 'Nasze usługi' },
            // { hashLink: true, to: '/#home', children: 'Dlaczego warto' },
            { hashLink: true, to: '/#pytania-i-odpowiedzi', children: 'Q&A' },
            { hashLink: true, to: '/#kontakt', children: 'Kontakt' },
          ]} />
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.common.hitam,
        py: '32px',
      }}>
        <Typography sx={{
          fontSize: '14px',
          fontWeight: 300,
          color: theme.palette.common.putih,
          textAlign: 'center',
        }}>© 2022 Grzegorz Urbanik. Wszystkie prawa zastrzeżone. </Typography>
      </Box>
    </>
  )
}
