import { Slide, useScrollTrigger } from "@mui/material";
import React from "react";

interface HideOnScrollOwnProps {
  children: JSX.Element;
}
type HideOnScrollProps = HideOnScrollOwnProps;

export default function HideOnScroll({ children }: HideOnScrollProps) {
  const trigger = useScrollTrigger({
    target: window,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
