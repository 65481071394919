import React from 'react';

import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

interface LinkOwnProps {
  to: string;
  children: JSX.Element | string;
  hashLink?: boolean;
}
export type LinkProps = LinkOwnProps;

export default function Link({ to, children, hashLink }: LinkProps) {
  return <MuiLink
    {...(hashLink ? {smooth: true} : {})}
    component={hashLink ? HashLink : RouterLink}
    sx={{ textDecoration: 'none', color: 'inherit' }}
    to={to}>
    {children}
  </MuiLink>
}
