import React from "react";
import { Box, Typography } from "@mui/material";
import { theme } from "app/styles/theme";
import listPoint from 'app/assets/list-point.svg';

interface LabelOwnProps {
  textContent: string;
}
type LabelProps = LabelOwnProps;

export default function Label({ textContent }: LabelProps) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '250px',
      py: '8px',
      border: '1px solid',
      borderImageSlice: 1,
      borderImageSource: `linear-gradient(to left, ${theme.palette.common.hitam}, rgba(0, 0, 0, 0))`,

    }}>
      <Box component="img" src={listPoint} alt='element listy' sx={{
        pl: '8px',
      }} />
      <Typography sx={{
        fontSize: '14px',
        fontWeight: 600,
        color: theme.palette.common.hitam,
        letterSpacing: 1.31,
        ml: '16px',
        pr: '8px'
      }}>
        {textContent}
      </Typography>
    </Box>
  )
}
