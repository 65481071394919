import { createTheme } from "@mui/material";
import "@mui/material/styles/createPalette";

declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    putih: string;
    hitam: string;
    biru: string;
    biru10: string;
    abu: string;
  }
}

export const theme = createTheme({
  palette: {
    common: {
      putih: '#FFFFFF',
      hitam: '#273C58',
      biru: '#4C74AC',
      biru10: '#EEF2F7',
      abu: '#7B868C',
    },
  },
  typography: {
    "fontFamily": `"Exo", sans-serif`,
    "fontSize": 16,
    button: {
      textTransform: 'none',
    },
    // h1: {
    //   color: 'red',
    // },
    // body1: {
    //   color: 'red',
    // },
   }
});
