
import React from 'react';
import Link, { LinkProps } from 'app/components/Link';
import { Typography, Button, useTheme } from '@mui/material';
import FooterColumn from 'app/components/FooterColumn';

interface NavigationFooterColumnOwnProps {
  navItems: LinkProps[];
  title: string;
}
type NavigationFooterColumnProps = NavigationFooterColumnOwnProps;

export default function NavigationFooterColumn({ navItems, title }: NavigationFooterColumnProps) {
  const theme = useTheme();

  return (
    <FooterColumn>
      <>
        <Typography sx={{
          color: theme.palette.common.hitam,
          fontWeight: 700,
          fontSize: '24px',
          mb: '16px',
        }}>
          {title}
        </Typography>
        {navItems.map(({ to, children, hashLink }: LinkProps, index: number) => (
          <Link
            key={index}
            to={to}
            hashLink={hashLink}
          >
            <Button
              sx={{
                p: 0,
                my: '8px',
                color: theme.palette.common.abu,
                fontWeight: 400,
                fontSize: '18px',
                display: 'block',
                textAlign: 'left',
              }}
            >
              {children}
            </Button>
          </Link>
        ))}
      </>
    </FooterColumn>
  )
}
